// const { useEffect } = require("react")

// const useElementOnScreen = () => {

//     const options = {
//         root: document.querySelector('#Reel-Main-Container'),
//         rootMargin: "0px",
//         threshold: 1.0
//     }

//     const containerRef = useRef(null)
//     const [isVisible, setIsVisible] = useState(false)

//     const callbackFunction = (entries) => {
//         const  [entry] = entries
//         console.log("callback function", entry);
//         setIsVisible(entry.isIntersecting)
//     }

//     useEffect(() => {

//         const observer = new IntersectionObserver(callbackFunction, options)
//         if(containerRef.current) observer.observe(containerRef.current)

//         return () => {
//             if(containerRef.current) observer.unobserve(containerRef.current)
//         }
//     }, [containerRef, options])

//     return [containerRef, isVisible]
// }

import { useEffect } from 'react';

let listenerCallbacks = new Map();

let observer;

function handleIntersections(entries) {
  entries.forEach(entry => {
    if (listenerCallbacks.has(entry.target)) {
      let cb = listenerCallbacks.get(entry.target);
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
        listenerCallbacks.delete(entry.target);
        cb();
      }
    }
  });
}

function getIntersectionObserver(observerConfig) {
  if (observer === undefined) {
    observer = new IntersectionObserver(
      entries => handleIntersections(entries),
      {
        rootMargin: '100px',
        threshold: '0.15',
        ...observerConfig
      }
    );
  }
  return observer;
}

export function useIntersection(elem, callback, observerConfig = {}) {
  useEffect(() => {
    let target = elem?.current;
    if (!!target) {
      let observer = getIntersectionObserver(observerConfig);
      listenerCallbacks.set(target, callback);
      observer.observe(target);
    }

    return () => {
      if (!!target) {
        listenerCallbacks.delete(target);
        observer.unobserve(target);
      }
    };
  }, []);
}









