import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDjCEdM-qN7GAqB5_MdHjazOvJUN4qn5Kk",
    authDomain: "wagmi-338311.firebaseapp.com",
    projectId: "wagmi-338311",
    storageBucket: "wagmi-338311.appspot.com",
    messagingSenderId: "440167015126",
    appId: "1:440167015126:web:99577b5c678f1dec3b1f77",
    measurementId: "G-6Z5C58ZGLB"
};

const firebaseInit = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseInit)
export default firebaseInit;
