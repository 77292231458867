import React, {useState, useRef} from 'react';
import './App.css';
import VideoPlayer from "./Components/VideoPlayer/VideoPlayer";
import ReactHlsPlayer from "react-hls-player";
import VideoFooter from "./Components/VideoPlayer/VideoFooter";
import VideoHeader from "./Components/VideoPlayer/VideoHeader";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import useElementOnScreen from "./Components/Utils/useElementOnScreen";
import {useIntersection} from './Components/Utils/useElementOnScreen'
import InitialView from './Components/InitialView';
import {SettingsInputHdmiOutlined} from '@mui/icons-material';
import Feedback from './Components/Feedback/Feedback';


// Firebase and Analytics
import {initializeApp} from 'firebase/app';
import firebaseInit from './Config/firebase';
import {getRemoteConfig, getValue, fetchAndActivate} from "firebase/remote-config";
import {getAnalytics, logEvent} from "firebase/analytics";
import usePlayerAnalytics from './Components/CustomHooks/PlayerEventHooks';
import {isIos, isAndroid} from './constants';


const App = () => {


    const [data, setRCData] = React.useState(null)
    const [isRCLoading, setRCLoadingStatus] = React.useState(true)
    const analytics = getAnalytics(firebaseInit);
    const remoteConfig = getRemoteConfig(firebaseInit);

    remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 10;


    let playerRef = [];
    let containerRef = [];

    let scrollDirection = ""

    let scrollTop = 0;


    const [initialView, setInitialView] = useState(true);


    React.useEffect(() => {
        fetchAndActivate(remoteConfig)
            .then(res => {
                const locationPathName = window.location.pathname.trim();
                const rcvalue = getValue(remoteConfig, "daily_stories")._value;
                if (rcvalue !== "") {
                    setRCData(JSON.parse(rcvalue)[locationPathName])
                }
            })
            .catch(err => console.log("rcvalue Remote config error occured", err))
            .finally(_ => setRCLoadingStatus(false))
    }, [remoteConfig])

    React.useEffect(() => {
        logEvent(analytics, "web_screen_open", {
            eventType: "pageload",
            screen: "shorts"
        })
    }, [])


    const handleScroll = e => {

        const currentElementScrollTop = e?.target?.scrollTop;

        //
        if (currentElementScrollTop !== null || currentElementScrollTop !== undefined) {
            if (scrollTop > currentElementScrollTop) {
                scrollDirection = "prev"
            } else {
                scrollDirection = "next"
            }
        }
        scrollTop = currentElementScrollTop;
        console.log("@scroll div", scrollDirection)

    }


    console.log("playerRef outside: ", playerRef);
    const handleContext = () => {
        let vid = document.querySelector(".App-Video-Player");
        console.log("vid", vid);
        vid.autoplay = true
        vid.muted = false
        vid.play();

        setInitialView(false)
        logEvent(analytics, "web_interaction", {
            eventType: "click"
        })
    }

    const renderVideoPlayer = (item, index) => {
        return (
            <ChildNode analytics={analytics} playerRef={playerRef} containerRef={containerRef} index={index} item={item}
                       key={`unique${index}`} initialView={initialView} watchFull={watchFull} data={data}
            />
        )
    }


    const InitialView = () => {
        const {context} = data;
        // const {context , handleButton, playerRef} = props;
        return (
            <div className={"InitialView"}>
                <div className={"wrapper"}>
                    <div className={"message"}>{context.message}</div>
                    <div className={"date"}>{context.date}</div>
                    <div className="button" onClick={() => handleContext()}>Let's Get Started!</div>
                </div>
            </div>
        )

    }


    const watchVlog = (index) => {
        logEvent(analytics, "web_watch_full_video", {
            eventType: "click",
            videoId: data.reels[index]?.videoId
        })
        const footer = document.querySelector(`#footer${index}`);
        const watchNow = document.querySelector(`#watchFull${index}`);
        watchNow.style.display = 'none'
        footer.style.display = 'none';
        const watch = document.querySelector(`#player${index}`)
        watch.controls = true
    }

    const watchFull = (index) => {
        return (
            <div className={"watchFull"} onClick={() => watchVlog(index)} id={`watchFull${index}`}>
                watch full
            </div>
        )

    }


    return (
        <>
            {isAndroid() ?
                <div className={"App-and"
                }>
                    <div className="App-Videos">
                        {data && data?.reels && Array.isArray(data.reels) && data.reels.map((itm, index) => {
                            return (
                                renderVideoPlayer(itm, index)
                            )
                        })
                        }
                    </div>
                    {data && data?.context && initialView && InitialView()}
                </div>
                :
                <div className={
                    "App"
                }>
                    <div className="App-Videos">
                        {data && data?.reels && Array.isArray(data.reels) && data.reels.map((itm, index) => {
                            return (
                                renderVideoPlayer(itm, index)
                            )
                        })
                        }
                    </div>
                    {data && data?.context && initialView && InitialView()}
                </div>
            }
        </>
    );
}

export default App;


const ChildNode = ({
                       item,
                       index,
                       playerRef,
                       containerRef,
                       handlePrevClick,
                       handleNextClick,
                       analytics,
                       initialView,
                       data,
                       watchFull,

                   }) => {

    playerRef[index] = React.createRef(null)
    containerRef[index] = React.createRef(null)

    const player = document.querySelector(`#player${index}`)

    let timeInterval = "";


    React.useEffect(() => {
        if (player) {
            player.addEventListener('play', () => {
                // console.log("Player played", player.currentTime)

                logEvent(analytics, "web_shorts_play", {
                    eventType: "click",
                    videoId: item.videoId,
                    startTime: player.currentTime
                });
            })

            player.addEventListener('pause', (e) => {

                timeInterval && clearInterval(timeInterval)
                logEvent(analytics, "web_shorts_pause", {
                    eventType: "click",
                    videoId: Math.floor(player.currentTime),
                    pauseTime: player.currentTime
                });
            })

            player.addEventListener('waiting', (e) => {
                logEvent(analytics, "web_shorts_buffer", {
                    eventType: "click",
                    videoId: item.videoId,
                });
            })
        }

        return () => {
            // if (player) player.removeEventListener('play')
        }

    }, [player])


    React.useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        }

        const callbackFunction = entries => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                let videos = document.querySelectorAll('.App-Video-Player');
                console.log("videos", videos);
                for (let i = 0; i < videos.length; i++) {

                    if (i === index) {
                        // let vid = document.querySelector(`#player${index}`);
                        console.log("vid", videos[i]);
                        videos[i].autoplay = true
                        videos[i].muted = false
                        videos[i].playsInline = true
                        videos[i].play();
                    } else {
                        videos[i].autoplay = false
                        videos[i].muted = true
                        videos[i].pause();
                    }
                }
                let vid = document.querySelector(`#player${index}`);
                console.log("vid", vid);
                vid.autoplay = true
                vid.muted = false
                vid.play();
            }
        }

        const observer = new IntersectionObserver(callbackFunction, options)
        const watch = document.querySelector(`#player${index}`)
        if (watch) observer.observe(watch)

        return () => {
            if (watch) observer.unobserve(watch)
        }
    }, [])


    const handleTimeInterval = _ => {
        timeInterval = setInterval(() => {
            console.log("@munna play", player?.currentTime)
        }, 10000)
    }

    return (
        <div className={"Reel-Main-Container"}>
            <VideoHeader reelsLength={data.reels.length} currentIndex={index + 1}/>
            {!initialView && item.type === 'vlog' && watchFull(index)}
            {!initialView && <VideoFooter context={data?.context} currentIndex={index} reelsData={data?.reels[index]}/>}
            <ReactHlsPlayer
                src={item.url}
                playerRef={playerRef[index]}
                className={"App-Video-Player"}
                id={`player${index}`}
                autoPlay={false}
                muted={true}
                loop
                controls
            />
        </div>
    )

}
