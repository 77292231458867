/**
 * Developer : Sourajit Karada
 */

import React from 'react';
// import MusicNoteIcon from "@material-ui/icons/MusicNote";
import './VideoFooter.css'

import {Whatshot, Lightbulb, Wc} from '@mui/icons-material';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import {logEvent} from "firebase/analytics";
import {analytics} from "../../Config/firebase";

const VideoFooter = props => {

    const [reaction, setClickedReaction] = React.useState(0)

    const { reelsData : { channelName, videoCaption, channelPic } } = props

    const handleReactionPress = type => {

        if (reaction !== 0) return;

        let analyticsParams = {
            eventType: "click",
            videoId: props?.videoId
        }

        switch (type) {

            case 1 : {
                analyticsParams = {...analyticsParams, reaction: 'learning'}
                break;
            }

            case 2 : {
                analyticsParams = {...analyticsParams, reaction: 'motivation'}
                break;
            }

            case 3 : {
                analyticsParams = {...analyticsParams, reaction: 'relatable'}
                break;
            }
            case 4: {
                analyticsParams = {...analyticsParams, reaction: 'entertaining'}
                break;
            }
                logEvent(analytics, "app_shorts_reaction", analyticsParams)
        }


        logEvent(analytics, "app_shorts_reaction", {
            eventType: "click",
            reaction: ""
        })

        setClickedReaction(type)
    }

    const getClassName = (type) => {
        if (type == reaction) {
            return `Reaction-Container Reaction-Selected-type${type}`
        }
        return "Reaction-Container"

    }

    const getIconClassName = type => {
        if (type == reaction) {
            return "Reaction-Container Icon-Selected";
        }
        return "Reaction-Container"
    }
    const openPage = (url) => {
        window.open(url)
    }

    const contextButton = () => {
        return (
            <div className={"contextButton"} onClick={()=>openPage('https://api.whatsapp.com/send/?phone=+918501961636&text=Hi%2C+I+would+like+to+know+more')}>
                Join WhatsApp for daily updates
                <p className={"contextText"}>7000+ students joined</p>
            </div>
        )
    
    }
    
    return (
        <div className={"App-Footer"} id ={`footer${props?.currentIndex}`}>
            <div className={"Creator-Channel-Container"}>
                <div>
                   { channelPic && <img
                        src={channelPic}
                        width="65px" height="65px" className={"Profile-Icon"}/>}
                </div>
                <div className={"Creator-Channel-Info-Container"}>
                   { channelName && <p className={"Channel-Name"}>{channelName}</p>}
                   { videoCaption && <div className={"Channel-Desc"}>{videoCaption}</div>}
                </div>
            </div>
            {props?.context?.type ==='onboarding'
             ?
             contextButton()
              :
            <div className={"App-Footer-Base"}>
                <div className={getClassName(1)} onClick={() => handleReactionPress(1)}>
                    <Lightbulb className={getIconClassName(1)} style={{fontSize: 32}}/>
                </div>

                <div className={getClassName(2)} onClick={() => handleReactionPress(2)}>
                    <LocalFireDepartmentIcon className={getIconClassName(2)} style={{fontSize: 32}}/>
                </div>
                {/* 
                <div className={getClassName(3)} onClick={() => handleReactionPress(3)}>
                    <Wc className={"Reaction-Container"} style={{fontSize: 32}}/>
                </div> */}
                <div className={getClassName(4)} onClick={() => handleReactionPress(4)}>
                    <InsertEmoticonIcon className={getIconClassName(4)} style={{fontSize: 32}}/>
                </div>
            </div>}
        </div>

    )
}


export default VideoFooter;
