import React from 'react';
import './InitialView.css'


const InitialView = props => {
    const {context , handleButton, playerRef} = props;

    return (
        <div className={"InitialView"}>
            <div className={"wrapper"}>
                <div className={"message"}>{context.message}</div>
                <div className={"date"}>{context.date}</div>
                <div className="button" onClick={handleButton(playerRef)}>Let's Get Started</div>
           </div>
        </div> 
    )

}

export default InitialView;