import React from 'react';
import './VideoHeader.css'


const VideoHeader = props => {
    const {currentIndex, total,reelsLength} = props;

    return (
        <div className={"App-Header"}>
           <div>{currentIndex}/{reelsLength}</div> 
        </div> 
    )

}

export default VideoHeader;