import React from 'react'
import {logEvent} from 'firebase/analytics';

const usePlayerAnalytics = params => {


    const {item, index, player, analytics} = params


    console.log("@params", params)


    React.useEffect(() => {
        if (!player) return null;

        player.addEventListener('play', () => {
            console.log("Player playing video")
            logEvent(analytics, "web_shorts_play", {
                eventType: "click",
                videoId: item.videoId,
            });
        })

        player.addEventListener('pause', () => {
            console.log("Player paused")

            logEvent(analytics, "web_shorts_pause", {
                eventType: "click",
                videoId: item.videoId,
            });
        })

        return () => {
            player.removeEventListener('play')
        }

    }, [player])

    return player;

}

export default usePlayerAnalytics;